nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #00923F;
    color: white;
    padding: 5px 10px;
    z-index: 11 !important;

    #nav-content {
        margin-left: 45px;
    }

    #nav-name-info {
        margin-right: 45px;
    }

    img {
        width: 120px;
    }

    img:hover {
        cursor: pointer;
    }

    #nav-name-info {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        #shopping-cart-icon {
            margin-top: 5px;
            margin-right: -5px;
        }

        p {
            margin-right: 15px;
        }

        #nav-button {
            margin-left: 15px;
            margin-right: 10px;
            border: 1px solid white;
            border-radius: 5px;
            padding: 5px 10px;
        }

        #nav-button:hover {
            cursor: pointer;
            background-color: white;
            color: #00923F;
        }
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        #nav-links {
            margin-left: 15px;
        }

        a {
            margin-left: 10px;
            margin-right: 10px;
            color: white;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .navbar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 15px;

        .navbar-title {
            cursor: pointer;
        }

        .navbar-title:hover {
            text-decoration: underline;
        }

        .navbar-content {
            display: none;
        } 

        .navbar-content-open {
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-top: 45px;
            background-color: white;
            border: 1px solid #00923F;

            a {
                color: black;
                font-family: "Avenir-Light";
                font-size: 14px;
                margin-top: 5px;
                margin-bottom: 5px;
                margin-left: 7px;
                margin-right: 7px;
            }
        }
    }

    #cart-number {
        position: relative;
        margin-left: -18px;
        margin-top: -25px;
        background-color: red;
        border-radius: 50%;
        // padding-left: 4px;
        // padding-right: 4px;
        font-size: 12px;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            margin-right: 1px;
        }
    }
}
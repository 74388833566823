#sign-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px !important;

    max-width: 600px;
    padding-bottom: 60px;

    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.25);

    #welcome-area {
        margin-top: 35px;
        text-align: center;

        p {
            margin-top: -15px;

            span {
                color: #00923F;
                text-decoration: underline;
            }

            span:hover {
                font-family: "Avenir Heavy";
                cursor: pointer;
            }
        }
    }

    #top-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .input-area {
            margin-bottom: 10px;
    
            p {
                margin-bottom: 5px;
                font-weight: bold;
            }
    
            input {
                width: 350px;
                padding: 10px 5px;
                border-radius: 5px;
                border-style:solid;
                border: 1px solid lightgrey;
            }
    
            input:focus {
                outline:0;
            }
        }

        #more-info {
            #instructions {
                width: 250px;
                text-align: center;
                margin-bottom: 30px;
            }
        }

        #create-account-button {
            margin-top: 45px !important;
            background-color: #00923F;
            color: white;
            padding: 5px 25px;
            border-radius: 15px;
            width: 115px;
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
        }

        #create-account-button:hover {
            cursor: pointer;
        }

        #sign-up-button {
            margin-top: 45px !important;
            background-color: #00923F;
            color: white;
            padding: 5px 25px;
            border-radius: 15px;
            width: 60px;
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
        }
    
        #sign-up-button:hover {
            cursor: pointer;
        }
    
        #error-area {
            color: red;
            font-style: italic;
            text-align: center;
            margin-bottom: -15px;
        }
    }

    #line-area {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 35px;

        .line {
            width: 85px;
            height: 1px;
            background-color: lightgrey;
        }

        p {
            margin: 10px;
            color: grey;
            font-style: italic;
        }
    }

    .third-party-area {
        background-color: #E7E7E7;
        text-align: center;
        width: 215px;
        padding: 10px;
        border-radius: 25px;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;

        img {
            width: 23px;
            height: 23px;
            margin-left: 5px;
        }

        span {
            flex-grow: 1;
        }

        #apple-image {
            width: 20px;
        }
    }

    .third-party-area:hover {
        cursor: pointer;
    }
}
#checkout {
    margin: 35px 45px;

    h2 {
        font-size: 22px;
    }

    h3 {
        font-weight: normal;
    }

    #checkout-content {
        margin-left: 10px;

        #checkout-content-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-top: -20px;

            #left {
                margin-right: 100px;
            }

            #right {
                width: 500px;
                padding-left: 20px;
                padding-right: 20px;
                background-color: #f7f7f7;
                border-radius: 5px;
            }
        }



        #selected-fridge-container {
            padding: 0px 10px;
            background-color: #E5E5E5;
            max-width: 400px;
            border-radius: 5px;
    
            .selected-fridge-content {
                padding: 10px 5px;
    
                p {
                    font-family: "Avenir-Medium";
                    padding: 0;
                    margin: 0;
                }
    
                .alert-text {
                    color: red;
                }
    
                span {
                    font-family: "Avenir-Heavy";
                }
    
                span:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    #trending-items-container {
        h3 {
            span {
                font-family: "Avenir-Heavy";
            }
        }

        #trending-items-area {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 20px;
            width: 475px;
            overflow: auto;

            .trending-item {
                width: 150px;
                text-align: center;
                font-family: "Avenir-Light";
                margin-right: 10px;

                img {
                    width: 150px;
                    height: 100px;
                }

                .plus-icon {
                    width: 20px;
                    height: 20px;
                }

                .plus-icon:hover {
                    cursor: pointer;
                }
            }
        }
    }

    #order-summary-container {
        margin-top: 20px;

        #empty-cart-text {
            font-family: "Avenir-Light";
            margin-bottom: 50px;
        }

        #cart-items-container {
            margin-top: 5px;

            .cart-item {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-left: -20px;
                margin-bottom: 25px;

                .cart-item-product-image {
                    width: 125px;
                    height: 80px;
                }

                .cart-item-info-area {
                    margin-right: auto;
                    margin-top: -15px;

                    h4 {
                        margin-bottom: 0px;
                    }

                    .plus-minus-region {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        img {
                            width: 20px;
                            height: 20px;
                        }

                        img:hover {
                            cursor: pointer;
                        }

                        p {
                            margin: 5px 10px;
                            font-family: "Avenir-Light";
                        }
                    }
                }

                .price-area {
                    margin-top: -15px;
                }
            }
        }

        #bottom-area {
            height: 150px;
            margin-top: auto;

            #cart-line {
                background-color: lightgray;
                width: 100%;
                height: 1px;
            }

            #delivery-date-area {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                font-family: "Avenir-Light";

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }

                img:hover {
                    cursor: pointer;
                }
            }

            #price-region {
                text-align: right;
                margin-top: 10px;
                margin-right: 10px;

                p {
                    margin-top: 0px;
                    margin-bottom: 5px;
                    font-family: "Avenir-Light";
                }

                h4 {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    font-size: 18px;
                }

                .horizontal-space {
                    margin-left: 5px;
                    margin-right: 6px;
                }
            }
        }

        #amount-region {
            margin-top: -10px;
            margin-bottom: 20px;
            font-family: "Avenir-Light";

            h4 {
                font-size: 18px;
                font-weight: normal;
                margin-bottom: 5px;
            }

            p {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }

        #product-info-area {
            margin-top: 15px;
            max-width: 500px;
            margin-bottom: 20px;

            .product-info-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: -10px;

                #num-in-cart {
                    margin-right: 30px;
                }

                #name {
                    margin-right: auto;
                }

                #price {
                    font-family: "Avenir-Light";
                }
            }
        }
    }

    .grey-line {
        max-width: 500px;
        height: 1px;
        background-color: lightgrey;
    }

    #pickup-container {
        margin-bottom: 40px;
        border: 2px solid lightgray;
        padding: 20px;

        .thick-grey-line {
            height: 2px;
            width: calc(100% - 10px);
            background-color: lightgrey;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    #checkout-button-region {
        max-width: 500px;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 5px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        #menu-button {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            color: black;
            font-family: "Avenir-Heavy";

            #arrow {
                font-size: 18px;
            }
        }

        #menu-button:hover {
            text-decoration: underline;
        }

        a {
            text-decoration: none;
        }

        #place-order-button-container {
            color: white;
            text-align: center;
            
            p {
                margin-left: auto;
                margin-right: auto;
                background-color: #00923F;
                width: 180px;
                font-size: 18px;
                padding: 10px;
                border-radius: 5px;
            }
    
            p:hover {
                cursor: pointer;
            }
    
            .disabled {
                background-color: lightgrey;
                color: grey;
            }
        }
    }
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#payment-method-container {
    margin-bottom: 40px;
    border: 2px solid lightgray;
    padding: 20px;

    h4 {
        margin: 0px;
        font-weight: normal;
        color: grey;
    }

    #payment-info-area {
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .thick-grey-line {
        height: 2px;
        width: calc(100% - 10px);
        background-color: lightgrey;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }

    .payment-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 490px;
        border-radius: 5px;
        padding-left: 10px;

        img {
            width: 30px;
            height: 30px;
        }

        p {
            margin-left: 10px;
            margin-top: 0px;
            margin-bottom: 0px;

            span {
                font-size: 20px;
                margin-right: 5px;
            }
        }

        #remove-card {
            margin-left: auto;
            width: 15px;
            height: 15px;
        }
    } 
    
    .payment-info:hover {
        cursor: pointer;
    }

    #selected-card {
        border: 1px solid #00923F;
    }
}

#add-card-container {
    max-width: 425px;

    h4 {
        font-weight: normal;
        margin-bottom: 15px;
    }

    #add-area {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        #cart-element-area {
            width: 450px;
        }

        button {
            background-color: #00923F;
            color: white;
            font-size: 14px;
            width: 100px;
            padding: 5px;
            border-radius: 5px;
            margin-top: -5px;
            margin-left: 10px;
            border: 0px;
        }

        button:hover {
            cursor: pointer;
        }
    }
}
#menu {
    margin: 35px 45px;

    #menu-container {
        #top-area {
            h2 {
                margin-left: 20px;
            }
    
            h2:hover {
                cursor: pointer;
            }
        }
    
        #across-section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: -5px;
    
            #search-container {
                margin-left: 20px;
                margin-bottom: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
        
                input {
                    padding: 5px 10px;
                    border-radius: 5px;
                    border-style: solid;
                    border: 1px solid lightgrey;
                    font-size: 16px;
                    font-family: "Avenir Medium";
                    width: 325px;
                }
        
                input:focus {
                    outline: 0;
                    background-color: lightgrey;
                    border-color: #00923F;
                }
        
                #search-button {
                    background-color: #00923F;
                    color: white;
                    padding: 5px 10px;
                    border-radius: 5px;
                    margin-left: 10px;
                    font-family: "Avenir Medium";
                }
        
                #search-button:hover {
                    cursor: pointer;
                }
            }
    
            #toggle-container {
                margin-left: 18px;
                margin-bottom: 15px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
    
                .item-area {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    margin-right: 15px;
    
                    input {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
    
                    p {
                        margin: 0px;
                    }
                }
    
                .item-area:hover {
                    cursor: pointer;
                }
            }
    
            #category-area {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                // margin-top: -10px;
                margin-right: 50px;
    
                p {
                    margin-left: 10px;
                    margin-right: 10px;
                }
    
                p:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
    
                #selected {
                    text-decoration: underline;
                }
            }
        }
    
        #product-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
                
            .product {
                width: 200px;
                height: 260px;
                border: 1px solid #E7E7E7;
                border-radius: 5px;
                margin-left: 20px;
                margin-right: 20px;
                margin-bottom: 20px;
                transition: 0.2s;
    
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
    
                img {
                    height: 132px;
                    border-radius: 5px;
                    margin-top: 5px;
                }
    
                .text-area {
                    text-align: center;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
    
            .product:hover {
                /* box shadow adapted from: https://www.w3schools.com/cssref/css3_pr_box-shadow.asp */
                box-shadow: 0px 0px 10px rgba(0,0,0,0.20);
                /* scale adapted from: https://www.w3schools.com/css/css3_animations.asp */
                transform: scale(1.05);
                cursor: pointer;
            }
        }
    }

    #cart-icon-container {
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: #00923F;
        padding: 15px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: 0.2s;

         /* box shadow adapted from: https://www.w3schools.com/cssref/css3_pr_box-shadow.asp */
         box-shadow: 0px 0px 10px rgba(0,0,0,0.30);

        #cart-icon {
            color: white;
            padding: 0px;
            width: 25px;
            height: 25px;
        }
    }

    #cart-icon-container:hover {
        /* box shadow adapted from: https://www.w3schools.com/cssref/css3_pr_box-shadow.asp */
        box-shadow: 0px 0px 10px rgba(0,0,0,0.60);
        /* scale adapted from: https://www.w3schools.com/css/css3_animations.asp */
        transform: scale(1.10);
        cursor: pointer;
    }

    #cart-number {
        position: fixed;
        margin-left: 10px;
        // margin-left: -12px;
        margin-top: -10px;
        background-color: red;
        border-radius: 50%;
        // padding-left: 4px;
        // padding-right: 4px;
        font-size: 12px;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            color: white;
            margin-right: 1px;
        }
    }
}

#modal-content {
    width: 500px;
    height: 550px;
    // padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    #fav-img {
        width: 30px;
        height: 30px;
        z-index: 10;
        margin-left: 0;
        margin-right: auto;
        margin-top: 0px;
        margin-bottom: -30px;
    }

    #fav-img:hover {
        cursor: pointer;
    }

    img {
        height: 215px;
        border-radius: 5px;
        margin-top: -10px;
    }

    .text-area {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
        font-family: "Avenir-Medium";
        width: 89%;

        h2 {
            font-family: "Futura-CondensedExtraBold";
            color: #3E3E3E;
            margin-top: 10px;
            margin-bottom: 0px;
        }

        .description {
            margin-top: 5px;
            margin-bottom: 0px;
        }

        .green-line {
            background-color: #9CC732;
            width: 200px;
            height: 1px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 15px;
        }

        .food-tag-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            
            .food-tag {
                font-family: 'Avenir-Medium';
                color: #3E3E3E;
                padding: 10px;
                border: 1px solid #9CC732;
                width: 115px;
                text-align: 'center';
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        .ingredients {
            text-align: left;

            h4 {
                margin-bottom: 0px;
                font-family: 'Avenir-Medium';
            }

            p {
                margin-top: 5px;
                font-family: 'Avenir-Light';
            }
        }

        .nutrient-info {
            text-align: left;
            margin-bottom: 30px;
            margin-right: 20px;

            h4 {
                margin-bottom: 10px;
                font-family: 'Avenir-Medium';
            }

            p {
                margin-top: 0px;
                margin-bottom: 0px;
                font-family: 'Avenir-Light';
            }

            .nutrient-info-line {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-bottom: 1px solid #B6B6B6;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }

    .add-cart-button {
        position: fixed;
        bottom: 0;
        margin-bottom: calc((100vh - 550px) / 2);
        z-index: 10;
        
        background-color: #00923F;
        color: white;
        font-family: "Avenir-Medium";
        width: 150px;
        border-radius: 20px;
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .add-cart-button:hover {
        cursor: pointer;
    }

    .gradient {
        width: 530px;
        height: 60px;
        position: fixed;
        bottom: 0;
        margin-bottom: calc((100vh - 580px) / 2);
        background-color: lightgrey;
        background: linear-gradient(0deg, white, transparent);
    }
}

#product-not-found {
    text-align: center;
}

#cart-negative-click-region {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.35);
}

#cart-negative-click-region:hover {
    cursor: pointer;
}
#cart-popout {
    background-color: white;
    width: 400px;
    height: 100vh;

    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 10;

    /* box shadow adapted from: https://www.w3schools.com/cssref/css3_pr_box-shadow.asp */
    box-shadow: 0px 0px 10px rgba(0,0,0,0.35);

    #cart-popout-content {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;

        #top-bar-area {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            #close-icon:hover {
                cursor: pointer;
            }
        }

        #cart-items-container {
            margin-top: 5px;

            .cart-item {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-left: -20px;
                margin-bottom: 25px;

                .cart-item-product-image {
                    width: 125px;
                    height: 80px;
                }

                .cart-item-info-area {
                    margin-right: auto;
                    margin-top: -15px;

                    h4 {
                        margin-bottom: 0px;
                    }

                    .plus-minus-region {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        img {
                            width: 20px;
                            height: 20px;
                        }

                        img:hover {
                            cursor: pointer;
                        }

                        p {
                            margin: 5px 10px;
                            font-family: "Avenir-Light";
                        }
                    }
                }

                .price-area {
                    margin-top: -15px;
                }
            }
        }

        #bottom-area {
            height: 150px;
            margin-top: auto;

            #cart-line {
                background-color: lightgray;
                width: 100%;
                height: 1px;
            }

            #price-region {
                text-align: right;
                margin-top: 10px;
                margin-right: 10px;

                p {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    font-family: "Avenir-Light";
                }

                h4 {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    font-size: 18px;
                }

                .horizontal-space {
                    width: 10px;
                    height: 5px;
                    margin-left: 6px;
                    margin-right: 6px;
                }
            }

            #checkout-button {
                background-color: #00923F;
                margin-left: 10px;
                margin-right: 10px;
                padding: 10px;
                text-align: center;
                border-radius: 10px;
                color: white;
                margin-top: 10px;
            }

            #checkout-button:hover {
                cursor: pointer;
            }

            a {
                text-decoration: none;
            }
        }
    }
}

body, html {
    font-family: "Avenir-Medium", sans-serif;
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: "Avenir Black";
    src: url(./assets/fonts/avenir/Avenir-Black.ttf);
}

@font-face {
    font-family: "Avenir Book";
    src: url(./assets/fonts/avenir/Avenir-Book.ttf);
}

@font-face {
    font-family: "Avenir Heavy";
    src: url(./assets/fonts/avenir/Avenir-Heavy.ttf);
}

@font-face {
    font-family: "Avenir Light";
    src: url(./assets/fonts/avenir/Avenir-Light.ttf);
}

@font-face {
    font-family: "Avenir Medium";
    src: url(./assets/fonts/avenir/Avenir-Medium.ttf);
}

@font-face {
    font-family: "Futura-Bold";
    src: url(./assets/fonts/futura/Futura-Bold.ttf);
}

@font-face {
    font-family: "Futura-CondensedExtraBold";
    src: url(./assets/fonts/futura/Futura-CondensedExtraBold.ttf);
}

@font-face {
    font-family: "Futura-CondensedMedium";
    src: url(./assets/fonts/futura/Futura-CondensedMedium.ttf);
}

@font-face {
    font-family: "Futura-Medium";
    src: url(./assets/fonts/futura/Futura-Medium.ttf);
}

@font-face {
    font-family: "Futura-MediumItalic";
    src: url(./assets/fonts/futura/Futura-MediumItalic.ttf);
}
#orders {
    #orders-content {
        margin: 20px 65px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        #no-orders-region {
            h3 {
                margin-bottom: 0px;
            }

            p {
                margin-top: 5px;
            }

            a {
                background-color: #00923F;
                color: white;
                text-decoration: none;
                padding: 5px 10px;
                border-radius: 5px;
            }
        }

        #all-orders-container {
            margin-left: 10px;
            margin-right: 65px;

            .order-preview {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 350px;
                margin-bottom: 20px;
                padding-top: 2px;
                padding-left: 2px;
                padding-bottom: 10px;
                border-radius: 5px;
                transition: 0.2s;
                border: 1px solid lightgrey;

                img {
                    width: 100px;
                    height: 65px;
                    margin-right: 15px;
                }

                h4 {
                    margin-top: 0px;
                    padding-top: 10px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: "Avenir-Light";
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }

            .order-preview:hover {
                color: #00923F;
                cursor: pointer;
                transform: scale(1.02);
                box-shadow: 0px 5px 10px rgba(0,0,0,0.25);
            }

            #selected-order {
                border: 1px solid #00923F;
                color: #00923F;
            }
        }
    }
}

#order-preview-container {
    width: 400px;
    margin-right: 55px;
    border: 1px solid #00923F;
    border-radius: 5px;
    margin-top: 64px;

    #top-order-area {
        background-color: #00923F;
        color: white;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;

        h4 {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    #order-info-area {
        font-family: "Avenir-Light";
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;

        div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;

            p {
                margin: 0px;
            }

            img {
                width: 25px;
                height: 25px;
                margin-right: 8px;
                margin-top: -2px;
            }
        }
    }
}

#item-area {
    .item-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: right;
        margin-right: 10px;
        margin-bottom: 20px;

        img {
            width: 100px;
            height: 65px;
        }

        h4 {
            margin: 0px;
        }

        p {
            margin: 0px;
            font-size: 14px;
        }
    }
}

#total-area {
    text-align: right;
    font-family: "Avenir-Light";
    margin-right: 10px;
    margin-bottom: 10px;

    h4 {
        margin: 0px;
    }

    p {
        margin: 0px;
    }
}

.grey-line {
    width: calc(100% - 20px);
    height: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: lightgrey;
}

#order-summary {
    #order-summary-content {
        padding: 40px 50px;

        #top-name-area {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            img {
                width: 90px;
                height: 90px;
                margin-right: 30px;
            }

            h2 {
                padding-top: -2px;
                margin-bottom: 0px;
                font-size: 32px;
            }

            p {
                margin-top: 0px;
                font-family: "Avenir-Light";
                font-size: 18px;
            }
        }

        #content-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 20px;
            margin-left: 10px;

            #pick-up-area {
                width: 500px;
                margin-right: 30px;

                .fridge-container {
                    width: 375px;

                    .fridge-delivery-date {
                        color: grey;
                        margin-left: 5px;
                    }
                }

                .fridge-container:hover {
                    transform: none;
                    cursor: pointer;
                }

                #info-text-section {
                    p {
                        font-size: 18px;
                        font-family: "Avenir-Light";
                        margin: 0px;
                        margin-bottom: 5px;

                        span {
                            font-weight: bold;
                        }
                    }
                }
            }

            #order-summary-area {
                h3 {
                    margin-bottom: -45px;
                }
            }
        }
    }
}

#account {
    margin: 35px 45px;
    
    #account-content {
        margin-left: 25px;
        margin-right: 15px;

        #account-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;

            .account-container {
                width: 450px;
                margin-right: 45px;

                .account-container-wrapper {
                    border: 2px solid lightgrey;
                    padding: 10px 15px;
                    margin-bottom: 30px;

                    .account-row {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .account-row-key-container {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                        }

                        .key {
                            width: 120px;
                            color: grey;
                        }

                        input {
                            margin-left: -10px;
                            margin-right: 20px;
                            font-size: 16px;
                            padding: 5px 10px;
                            border-radius: 5px;
                            border: 1px solid lightgrey;
                            width: 100px;
                        }

                        .change-button {
                            // width: 75px;
                            border-radius: 25px;
                            border: 1px solid lightgrey;

                            p {
                                color: grey;
                                margin: 0px;
                                padding: 3px 18px;
                                text-align: center;
                                font-size: 14px;
                            }
                        }

                        .change-button:hover {
                            cursor: pointer;
                            background-color: lightgrey;

                            p {
                                color: white;
                            }
                        }
                    }
                }

                .account-line {
                    width: 100%;
                    height: 2px;
                    background-color: lightgrey;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
        }

        .account-payment-method {
            margin-bottom: 0px !important;
            border: none !important;
            padding: 10px !important;
        }

        #add-fridge-button {
            color: #00923F;
            font-family: "Avenir Heavy";
        }

        #add-fridge-button:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        #logout-button:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .separator-line {
        display: block !important;
    }
}

#add-fridge-content {
    margin: 20px 65px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    
    #description-area {
        margin-right: 75px;

        p, li {
            max-width: 490px;
        }

        li {
            margin-top: 10px;
            font-family: "Avenir Light";
        }
    }

    .separator-line {
        display: none;
        width: calc(100vw - 50px);
        max-width: 550px;
        height: 1px;
        background-color: lightgrey;
        margin-top: -20px;
        margin-bottom: 30px;
    }       

    #add-fridge-content-container {
        margin-top: 40px;
    }

    #or-separator {
        margin: 40px 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .or-line {
            width: 75px;
            height: 1px;
            background-color: grey;
        }

        p {
            color: grey;
            font-style: italic;
            font-family: "Avenir Medium";
            margin: 0px 25px;
        }
    }

    .access-input-container {
        h3 {
            color: #00923F;
            margin-bottom: 0px;
        }

        p {
            font-size: 14px;
            font-style: italic;
            margin-top: 3px;
        }

        .input-area {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            input {
                width: 250px;
                height: 20px;
                padding: 5px 10px;
                border-radius: 5px;
                border: 1px solid grey;
                margin-right: 20px;
            }

            .submit-button {
                color: #00923F;
                border: 1px solid #00923F;
                border-radius: 20px;
                padding: 5px 20px;
            }

            .submit-button:hover {
                cursor: pointer;
                background-color: #00923F;
                color: white;
            }
        }
    }

    #fridge-pin-field, #corporate-email-pin-field {
        input {
            letter-spacing: 20px;
            font-size: 24px;
            width: 130px;
            padding: 10px;
        }

        input:focus {
            width: 145px;
        }
    }
}

#alert-info-container {
    margin-top: 100px;
    margin-right: 30px;
    width: 400px;

    padding: 10px;
    background-color: #E5E5E5;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.25);
    max-width: 500px;
    border-radius: 5px;

    position: fixed;
    top: 0;
    right: 0;

    h4 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    p {
        font-family: "Avenir-Light";
        margin-top: 5px;
        margin-bottom: 0px;
    }
}

#fridge-list-container {
    margin-bottom: 75px;

    .account-fridge-container {
        padding: 10px;
        background-color: #ebfaf6;
        box-shadow: 0px 2px 3px rgba(0,0,0,0.25);
        width: 425px;
        border-radius: 5px;
        margin-bottom: 20px;
        transition: 0.2s;
    
        p {
            margin: 0px;
        }
    
        .name-area {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
    
            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
    
            .fridge-name {
                font-family: "Avenir-Heavy";
            }
        }
    
        .info-container {
            // margin-left: 28.5px;
            p {
                font-family: "Avenir-Light";
            }
    
            .fridge-specific-instructions {
                color: #00923F;
            }
    
            .fridge-delivery-area {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
    
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                }
            }
        } 
    }
}

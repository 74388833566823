#how-it-works {
    margin: 20px 45px;
    
    #how-it-works-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 10px 30px;

        #info-panel {
            margin-right: 100px;
            max-width: 650px;

            .content-area {
                margin-bottom: 30px;
    
                h3 {
                    margin-bottom: 5px;
                }
    
                p {
                    font-family: "Avenir-Light";
                    margin-top: 0px;
                }
    
                .button {
                    background-color: #00923F;
                    color: white;
                    padding: 10px;
                    width: 100px;
                    text-align: center;
                    border-radius: 10px;
                    margin-bottom: 30px;
                }
    
                a {
                    text-decoration: none;
                }
            }
        }

        iframe {
            margin-top: 30px;
            width: 560px;
            height: 315px;
        }
    }
}
#fridge-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #fridges {
        // width: 500px;
    
        .fridge-selected {
            border: 1px solid #00923F;
        }
        
        .fridge-container {
            padding: 10px;
            background-color: #ebfaf6;
            box-shadow: 0px 2px 3px rgba(0,0,0,0.25);
            width: 450px;
            border-radius: 5px;
            margin-bottom: 20px;
            transition: 0.2s;
    
            p {
                margin: 0px;
            }
    
            .name-area {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
    
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }
    
                .fridge-name {
                    font-family: "Avenir-Heavy";
                }
            }
    
            .info-container {
                // margin-left: 28.5px;
                p {
                    font-family: "Avenir-Light";
                }
    
                .fridge-specific-instructions {
                    color: #00923F;
                }
    
                .fridge-delivery-area {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
    
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }
            } 
        }
    
        .fridge-container:hover {
            /* box shadow adapted from: https://www.w3schools.com/cssref/css3_pr_box-shadow.asp */
            // box-shadow: 0px 0px 10px rgba(0,0,0,0.35);
            /* scale adapted from: https://www.w3schools.com/css/css3_animations.asp */
            transform: scale(1.02);
            cursor: pointer;
        }

        .fanned-out {            
            margin-top: -15px;
        }
    }

    #fridge-info-container {
        margin-top: 100px;
        margin-right: 30px;
        width: 400px;

        padding: 10px;
        background-color: #E5E5E5;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.25);
        max-width: 500px;
        border-radius: 5px;

        position: fixed;
        top: 0;
        right: 0;

        h4 {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        p {
            font-family: "Avenir-Light";
            margin-top: 5px;
            margin-bottom: 0px;
        }
    }
}
